.Home {
  background: url("./assets/jesus_looking_sky.jpg") no-repeat center;
  background-size: cover;
}
.About {
  background: url("./assets/jesus_crusifide.jpg") no-repeat center;
  background-size: cover;
}


  @font-face {
    font-family: 'superman';
    src: url(./font.otf);
  }
